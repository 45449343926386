/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React, { useContext } from 'react';
import { App, ProviderProps as AppProps } from '@volkswagen-onehub/oneaudi-os-react';
import { StageGeneric } from './components/StageGeneric';
import { ContentContext } from './context';

const FeatureApp: React.FC<AppProps> = ({ ...inherited }: AppProps) => {
  const app = {
    name: __FEATURE_APP_NAME__,
    version: __FEATURE_APP_VERSION__,
  };

  const appConfig: { [k: string]: any } = { appName: __FEATURE_APP_NAME__ };

  const context = useContext(ContentContext);

  return (
    <App config={appConfig} app={app} {...inherited}>
      <StageGeneric {...context.content} />
    </App>
  );
};

export default FeatureApp;
