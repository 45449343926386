import { AudiPlatformProvider, audiLightTheme, audiDarkTheme } from '@audi/audi-ui-react';
import {
  MaterialDisclaimer,
  ResponsiveMedia,
} from '@volkswagen-onehub/aoa-shared-editorial-components';
import { shape, string, oneOf, bool, arrayOf } from 'prop-types';
import React from 'react';

import { StageContent, stageContentPropTypes } from './StageContent';
import { Container, MediaContainer, ContentContainer, MaterialDisclaimerContainer } from './styles';
import { stageGenericDefaults } from './testData';

const AOA_SITE_HEADER = '72px';

class StageGeneric extends React.Component {
  state = {
    delayStageContent: this.props.delayStageContent,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.delayStageContent !== state.prevDelayStageContent) {
      return {
        prevDelayStageContent: props.delayStageContent,
        delayStageContent:
          props.delayStageContentUntil === 'load' || !props.media.videoEnabled
            ? 'none'
            : props.delayStageContent,
      };
    }

    return null;
  }

  handlePlaybackEnded = () => {
    this.setState({ delayStageContent: 'none' });
  };

  handlePlaybackStarted = () => {
    const { delayStageContentUntil } = this.props;
    const showStageContent = () => this.setState({ delayStageContent: 'none' });

    const mapTimeOut = { load: 0, oneSecond: 1000, threeSeconds: 3000 };
    if (delayStageContentUntil === 'oneSecond' || delayStageContentUntil === 'threeSeconds') {
      setTimeout(showStageContent, mapTimeOut[delayStageContentUntil]);
    }
  };

  render() {
    const { delayStageContent } = this.state;
    const {
      theme,
      height,
      stageContent,
      horizontalAlignment,
      horizontalAlignmentSmall,
      verticalAlignment,
      verticalAlignmentSmall,
      media,
      materialDisclaimer,
      overlapContent,
      reservedSpace,
    } = this.props;

    const stageContentProps = {
      ...stageContent,
      horizontalAlignment,
      horizontalAlignmentSmall,
      delayStageContent,
    };

    return (
      <AudiPlatformProvider theme={theme === 'dark' ? audiDarkTheme : audiLightTheme}>
        <Container
          height={height}
          reservedSpace={reservedSpace}
          verticalAlignment={verticalAlignment}
          verticalAlignmentSmall={verticalAlignmentSmall}
          overlapContent={overlapContent}
        >
          <MediaContainer>
            <ResponsiveMedia
              {...media}
              theme={theme}
              onPlayedOnce={this.handlePlaybackEnded}
              onPlay={this.handlePlaybackStarted}
              cover
            />
            <MaterialDisclaimerContainer>
              <MaterialDisclaimer materialDisclaimer={materialDisclaimer} />
            </MaterialDisclaimerContainer>
          </MediaContainer>
          <ContentContainer
            overlapContent={overlapContent}
            verticalAlignment={verticalAlignment}
            verticalAlignmentSmall={verticalAlignmentSmall}
          >
            <StageContent {...stageContentProps} />
          </ContentContainer>
        </Container>
      </AudiPlatformProvider>
    );
  }
}

const srcShape = shape({
  src: string.isRequired,
  poster: string.isRequired,
});

const materialDisclaimerShape = shape({
  disclaimer: string,
  theme: oneOf(['light', 'dark']),
  position: oneOf(['bottomLeft', 'bottomRight', 'topLeft', 'topRight']),
});

StageGeneric.propTypes = {
  theme: oneOf(['light', 'dark']).isRequired,
  height: oneOf(['eighty', 'hundred']),
  overlapContent: bool,
  horizontalAlignment: oneOf(['left', 'center', 'right']),
  horizontalAlignmentSmall: oneOf(['left', 'center', 'right']),
  verticalAlignment: oneOf(['top', 'center', 'bottom']),
  verticalAlignmentSmall: oneOf(['top', 'center', 'bottom']),
  media: shape({
    fadeEnabled: bool,
    fade: shape({
      color: string, // hex color, if none present will use theme color
      opacity: string, // 0 -1
      height: string, // percentage 0-100
      direction: oneOf(['top', 'bottom', 'left', 'right']),
    }),
    imageEnabled: bool.isRequired,
    videoEnabled: bool.isRequired,
    image: shape({
      alt: string,
      srcSet: arrayOf(
        shape({
          bp: oneOf(['xs', 's', 'm', 'l', 'xl', 'xxl']),
          src: string,
        })
      ),
    }),
    video: shape({
      autoplay: bool,
      loop: bool,
      srcs: shape({
        xs: srcShape,
        s: srcShape,
        m: srcShape,
        l: srcShape,
        xl: srcShape,
        xxl: srcShape,
      }),
    }),
  }).isRequired,
  materialDisclaimer: shape({
    xs: materialDisclaimerShape,
    s: materialDisclaimerShape,
    m: materialDisclaimerShape,
    l: materialDisclaimerShape,
    xl: materialDisclaimerShape,
    xxl: materialDisclaimerShape,
  }).isRequired,
  stageContent: shape(stageContentPropTypes),
  delayStageContent: oneOf(['none', 'all', 'excludeHeading']),
  delayStageContentUntil: oneOf(['load', 'oneSecond', 'threeSeconds', 'playedOnce']),
  reservedSpace: string,
};

StageGeneric.displayName = 'StageGeneric';

StageGeneric.defaultProps = Object.assign(stageGenericDefaults, { reservedSpace: AOA_SITE_HEADER });

export { StageGeneric };
