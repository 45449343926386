/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';

import { ContentContextProvider } from './context';
import App from './FeatureApp';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {},
    externals: {
      react: '^16.13.1 || ^17.0.2',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '*',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-content-service': '^1.0.0',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const logger = featureServices['s2:logger'];
    const contentService = featureServices['audi-content-service'];
    logger?.info('Feature App created.');

    return {
      render: () => {
        return (
          <ContentContextProvider contentService={contentService as ContentServiceV1}>
            <App />
          </ContentContextProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
