import { Button, Text } from '@audi/audi-ui-react';
import { Logo } from '@volkswagen-onehub/aoa-shared-editorial-components';
import { arrayOf, bool, oneOf, shape, string } from 'prop-types';
import React from 'react';
import uuid from 'react-uuid';

import {
  Container,
  ContentContainer,
  CTAsContainer,
  CTAText,
  HeadingContainer,
  LogoPWrapper,
  LogoH1Wrapper,
  StyledButtonGroup,
} from './styles';

const ctasHorizontalAlignment = (horizontalAlignment) =>
  horizontalAlignment === 'left' || horizontalAlignment === 'right' ? 'flex-start' : 'center';

const getCTAsResponsiveAlignment = (horizontalAlignment, horizontalAlignmentSmall) => {
  const alignment = ctasHorizontalAlignment(horizontalAlignment);
  return {
    xs: horizontalAlignmentSmall,
    s: horizontalAlignmentSmall,
    m: alignment,
    l: alignment,
    xl: alignment,
    xxl: alignment,
  };
};

const LogoWrapper = ({ heading, logo, horizontalAlignment, horizontalAlignmentSmall }) =>
  logo.src ? (
    heading ? (
      <LogoPWrapper
        horizontalAlignment={horizontalAlignment}
        horizontalAlignmentSmall={horizontalAlignmentSmall}
      >
        <Logo src={logo.src} alt={logo.alt} heights={logo.heights} />
      </LogoPWrapper>
    ) : (
      <LogoH1Wrapper
        horizontalAlignment={horizontalAlignment}
        horizontalAlignmentSmall={horizontalAlignmentSmall}
      >
        <Logo src={logo.src} alt={logo.alt} heights={logo.heights} />
      </LogoH1Wrapper>
    )
  ) : null;

LogoWrapper.displayName = 'LogoWrapper';

const StageContent = ({
  heading = {},
  subHeading = {},
  CTAs = [],
  CTAsResponsiveLayout,
  horizontalAlignment,
  horizontalAlignmentSmall,
  logo = {},
  description = {},
  delayStageContent,
  numberOfColumns,
  alignCtaToBottomOnSmall,
}) => (
  <Container horizontalAlignment={horizontalAlignment} numberOfColumns={numberOfColumns}>
    <HeadingContainer
      data-test-id="stage-content-heading"
      delayStageContent={delayStageContent}
      horizontalAlignment={horizontalAlignment}
      horizontalAlignmentSmall={horizontalAlignmentSmall}
    >
      <LogoWrapper
        heading={heading}
        logo={logo}
        horizontalAlignment={horizontalAlignment}
        horizontalAlignmentSmall={horizontalAlignmentSmall}
      />
      <Text
        weight={heading.embolden ? 'bold' : 'normal'}
        variant={heading.variant}
        as={heading.htmlTag}
      >
        {heading.text}
      </Text>
    </HeadingContainer>

    <ContentContainer
      delayStageContent={delayStageContent}
      horizontalAlignment={horizontalAlignment}
      horizontalAlignmentSmall={horizontalAlignmentSmall}
      data-test-id="stage-content-content"
    >
      <Text
        weight={subHeading.embolden ? 'bold' : 'normal'}
        variant={subHeading.variant}
        as={subHeading.htmlTag}
      >
        {subHeading.text}
      </Text>
      <Text weight={description.embolden ? 'bold' : 'normal'} variant={description.variant} as="p">
        {description.text}
      </Text>
    </ContentContainer>
    <CTAsContainer
      alignCtaToBottomOnSmall={alignCtaToBottomOnSmall}
      ctaHorizontalLayout={getCTAsResponsiveAlignment(
        horizontalAlignment,
        horizontalAlignmentSmall
      )}
      data-test-id="stage-content-cta"
    >
      <StyledButtonGroup layout={CTAsResponsiveLayout} variant="block-buttons">
        {CTAs.map(({ href, variant, newWindow, text }) => (
          <Button variant={variant} key={uuid()} href={href} newWindow={newWindow}>
            <CTAText>{text}</CTAText>
          </Button>
        ))}
      </StyledButtonGroup>
    </CTAsContainer>
  </Container>
);

export const stageContentPropTypes = {
  numberOfColumns: string,
  heading: shape({
    text: string,
    variant: oneOf(['order1', 'order2', 'order3', 'order4', 'display1', 'display2']),
    htmlTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    embolden: bool,
  }),
  subHeading: shape({
    text: string,
    variant: oneOf(['order1', 'order2', 'order3', 'order4', 'display1', 'display2']),
    htmlTag: oneOf(['h2', 'h3', 'h4', 'h5', 'h6', 'p']),
    embolden: bool,
  }),
  description: shape({
    text: string,
    variant: oneOf(['copy1', 'copy2', 'copy3']),
    embolden: bool,
  }),
  CTAs: arrayOf(
    shape({
      href: string,
      variant: oneOf(['primary', 'secondary', 'text']),
      text: shape({
        xs: string,
        s: string,
        m: string,
        l: string,
        xl: string,
        xxl: string,
      }),
      newWindow: bool,
    })
  ),
  CTAsResponsiveLayout: shape({
    xs: oneOf(['horizontal', 'vertical']),
    s: oneOf(['horizontal', 'vertical']),
    m: oneOf(['horizontal', 'vertical']),
    l: oneOf(['horizontal', 'vertical']),
    xl: oneOf(['horizontal', 'vertical']),
    xxl: oneOf(['horizontal', 'vertical']),
  }),
  alignCtaToBottomOnSmall: bool,
};

StageContent.displayName = 'StageContent';
StageContent.propTypes = {
  ...stageContentPropTypes,
  horizontalAlignment: oneOf(['left', 'center', 'right']),
  horizontalAlignmentSmall: oneOf(['left', 'center', 'right']),
  delayStageContent: oneOf(['none', 'all', 'excludeHeading']),
};

export { StageContent };
