export const stageContentDefaults = {
  numberOfColumns: '21',
  horizontalAlignment: 'center',
  heading: '<h1><strong>Please enter heading text</strong></h1>',
  logo: {
    src: '',
    alt: '',
    heights: {
      xs: '18',
      s: '18',
      m: '24',
      l: '36',
      xl: '48',
      xxl: '60',
    },
  },
  subHeading: '<p>Please enter sub-heading text</p>',
  description: '',
  CTAs: [
    {
      text: {
        xs: 'Enter CTA Text',
        m: '',
      },
      href: 'primaryAction',
      variant: 'primary',
      newWindow: false,
    },
    {
      text: {
        xs: 'Enter CTA Text',
        m: '',
      },
      href: 'secondaryAction',
      variant: 'secondary',
      newWindow: false,
    },
  ],
  CTAsResponsiveLayout: {
    xs: 'vertical',
    s: 'vertical',
    m: 'horizontal',
    l: 'horizontal',
    xl: 'horizontal',
    xxl: 'horizontal',
  },
  ctaVerticalAlignmentSmall: 'bottom',
};
