import {
  getBackgroundColor,
  createMediaQuery,
  getThemeFontColor,
} from '@volkswagen-onehub/aoa-shared-editorial-components';
import styled from 'styled-components';

const getHeight = (height) => (height === 'eighty' ? 80 : 100);

const columnWidth = 4;

const alignmentMappings = {
  top: 'initial',
  center: 'center',
  bottom: 'flex-end',
};

const extendedMargins = {
  xs: '-50px',
  m: '-100px',
  l: '-200px',
};

const calcHeight = ({ height, reservedSpace }) =>
  `calc(${getHeight(height)}vh - var(--browser-address-bar) - ${reservedSpace})`;

export const Container = styled.div`
  --browser-address-bar: 0px;
  background-color: ${getBackgroundColor({ light: 'white', dark: 'black' })};
  display: flex;
  justify-content: center;
  position: relative;
  height: fit-content;
  min-height: ${calcHeight};

  ${(p) =>
    p.overlapContent &&
    createMediaQuery(
      {
        'margin-bottom': extendedMargins,
      },
      p
    )}

  ${createMediaQuery({
    'align-items': {
      m: (p) => alignmentMappings[p.verticalAlignment],
      s: (p) => alignmentMappings[p.verticalAlignmentSmall],
    },
  })}
`;

export const MediaContainer = styled.div`
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
`;

const hasOverlap = (overlap) => Boolean(overlap);
const isNotAlignTop = (alignment) => alignment !== 'top';

const isOverlapStyling = (p, isSmall = false) =>
  hasOverlap(p.overlapContent) &&
  isNotAlignTop(isSmall ? p.verticalAlignmentSmall : p.verticalAlignment);

export const ContentContainer = styled.div`
  flex-grow: 1;

  ${createMediaQuery({
    'margin-top': {
      s: (p) => (isOverlapStyling(p, true) ? '-50px' : '0px'),
      m: (p) => (isOverlapStyling(p) ? '-100px' : '0px'),
      l: (p) => (isOverlapStyling(p) ? '-200px' : '0px'),
    },
  })}
`;

export const MaterialDisclaimerContainer = styled.div`
  > div {
    ${createMediaQuery({
      m: {
        'box-sizing': 'border-box',
        'max-width': `${columnWidth * 11}%`,
      },
    })}
  }
`;

export const ScrollAnchorContainer = styled.div`
  display: none;
  position: absolute;

  a > svg {
    color: ${getThemeFontColor('primary')};
  }

  ${createMediaQuery({
    m: {
      bottom: 0,
      display: 'flex',
      'justify-content': 'center',
      width: '100%',
    },
  })}
`;
