export const stageGenericDefaults = {
  theme: 'dark',
  height: 'hundred',
  overlapContent: false,
  verticalAlignmentSmall: 'center',
  verticalAlignment: 'center',
  horizontalAlignmentSmall: 'center',
  horizontalAlignment: 'center',
  media: {
    fadeEnabled: true,
    fade: {
      direction: 'top',
      height: '80%',
      opacity: '1',
    },
    imageEnabled: true,
    image: {
      alt: 'Image of an Audi',
      srcSet: [
        {
          bp: 'xs',
          src: 'https://www.audiusa.com/content/dam/nemo/us/Innovation/Quattro/1277x718_Innovation_Teaser/1277x718_2018-Audi-Technology-Quattro-pg-P2-A-Gripping-Proposition-1920x1080.jpg',
        },
      ],
    },
    videoEnabled: false,
    video: {
      autoplay: true,
      loop: true,
      srcs: {
        xs: {
          src: '',
          poster: '',
        },
        s: {
          src: '',
          poster: '',
        },
        m: {
          src: '',
          poster: '',
        },
        l: {
          src: '',
          poster: '',
        },
        xl: {
          src: '',
          poster: '',
        },
        xxl: {
          src: '',
          poster: '',
        },
      },
    },
  },
  materialDisclaimer: {
    xs: {
      position: 'bottomLeft',
      theme: 'dark',
      disclaimer: 'disclaimers!',
    },
    s: {
      position: 'bottomRight',
      theme: 'dark',
      disclaimer: 'disclaimers!',
    },
    m: {
      position: 'topLeft',
      theme: 'dark',
      disclaimer: 'disclaimers!',
    },
    l: {
      position: 'topRight',
      theme: 'dark',
      disclaimer: 'disclaimers!',
    },
    xl: {
      position: 'bottomLeft',
      theme: 'dark',
      disclaimer: 'disclaimers!',
    },
    xxl: {
      position: 'bottomRight',
      theme: 'dark',
      disclaimer: 'disclaimers!',
    },
  },
  stageContent: {
    numberOfColumns: '21',
    heading: {
      text: 'Welcome to StageGeneric',
      variant: 'display1',
      htmlTag: 'h1',
      embolden: true,
    },
    subHeading: {
      text: 'With gorgeous display.',
      variant: 'order2',
      htmlTag: 'h2',
      embolden: false,
    },
    description: {
      text: 'Edit content to get started!',
      variant: 'copy1',
      embolden: false,
    },
    logo: {
      src: '',
      alt: '',
      heights: {
        xs: '18',
        s: '18',
        m: '24',
        l: '36',
        xl: '48',
        xxl: '60',
      },
    },
    CTAs: [
      {
        text: {
          xs: 'Primary',
          m: '',
        },
        href: '#',
        variant: 'primary',
        newWindow: false,
      },
      {
        text: {
          xs: 'Secondary',
          m: '',
        },
        href: '#',
        variant: 'secondary',
        newWindow: false,
      },
    ],
    CTAsResponsiveLayout: {
      xs: 'vertical',
      s: 'vertical',
      m: 'horizontal',
      l: 'horizontal',
      xl: 'horizontal',
      xxl: 'horizontal',
    },
    alignCtaToBottomOnSmall: false,
  },
  delayStageContent: 'none',
  delayStageContentUntil: 'load',
  reservedSpace: '0',
};

const videoSrc =
  'https://www.audiusa.com/content/dam/nemo/us/models/Q3/Q3-a/1920x1080-banner-video/1920x1080_2020-Audi-Q3-001Mov-1_min.m4v';

export const videoProps = {
  ...stageGenericDefaults,
  theme: 'light',
  verticalAlignment: 'top',
  horizontalAlignment: 'left',
  stageContent: {
    ...stageGenericDefaults.stageContent,
    heading: {
      text: 'Seamless Video Integration',
      variant: 'display1',
      htmlTag: 'h1',
      emolden: true,
    },
    subHeading: {
      text: 'With multiple layout options',
      variant: 'order2',
      htmlTag: 'h2',
      emolden: false,
    },
    description: {
      text: 'Edit content to get started!',
      variant: 'copy1',
      emolden: false,
    },
  },
  media: {
    videoEnabled: true,
    imageEnabled: false,
    video: {
      autoplay: true,
      loop: true,
      srcs: {
        xs: {
          src: videoSrc,
          poster: '',
        },
        s: {
          src: videoSrc,
          poster: '',
        },
        m: {
          src: videoSrc,
          poster: '',
        },
        l: {
          src: videoSrc,
          poster: '',
        },
        xl: {
          src: videoSrc,
          poster: '',
        },
        xxl: {
          src: videoSrc,
          poster: '',
        },
      },
    },
  },
};

export const noMediaProps = {
  ...stageGenericDefaults,
  theme: 'dark',
  verticalAlignment: 'center',
  horizontalAlignment: 'center',
  materialDisclaimer: {
    xs: {},
    s: {},
    m: {},
    l: {},
    xl: {},
    xxl: {},
  },
  stageContent: {
    ...stageGenericDefaults.stageContent,
    heading: {
      text: 'No media necessary',
      variant: 'display2',
      htmlTag: 'h1',
      emolden: true,
    },
    subHeading: {
      text: 'To look this good',
      variant: 'order3',
      htmlTag: 'h2',
      emolden: false,
    },
    description: {
      text: 'Edit content to get started!',
      variant: 'copy1',
      emolden: false,
    },
  },
  media: {
    videoEnabled: false,
    imageEnabled: false,
  },
};

export const noDelay = {
  ...stageGenericDefaults,
  delayStageContent: null,
  delayStageContentUntil: null,
};

export const delay = {
  ...stageGenericDefaults,
  delayStageContent: 'all',
  delayStageContentUntil: 'threeSeconds',
};
