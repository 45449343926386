import { injectOSXFontSmoothing, ButtonGroup } from '@audi/audi-ui-react';
import {
  getResponsiveSpacing,
  injectTypography,
  createMediaQuery,
  getBreakpoint,
  responsiveContent,
} from '@volkswagen-onehub/aoa-shared-editorial-components';
import styled, { css } from 'styled-components';

export const columnWidth = 4;
export const sideSpacing2Cols = columnWidth * 2;

export const Container = styled.div`
  margin-left: ${sideSpacing2Cols}%;
  margin-right: ${sideSpacing2Cols}%;
  margin-top: ${getResponsiveSpacing('xl')};
  margin-bottom: ${getResponsiveSpacing('xl')};

  @media (min-width: ${getBreakpoint('m')}) {
    width: ${({ numberOfColumns }) => `${numberOfColumns * columnWidth}%;`};
    ${({ horizontalAlignment, numberOfColumns }) =>
      horizontalAlignment === 'right'
        ? `margin-left: ${
            100 - numberOfColumns * columnWidth - sideSpacing2Cols
          }%; margin-right: ${sideSpacing2Cols}%;`
        : horizontalAlignment === 'center'
        ? 'margin-left: auto; margin-right: auto;'
        : ''};
  }
`;

const isRightorLeft = (align) => align === 'left' || align === 'right';

const handleAlignment = createMediaQuery({
  'text-align': {
    xs: (p) => (isRightorLeft(p.horizontalAlignmentSmall) ? 'left' : 'center'),
    m: (p) => (isRightorLeft(p.horizontalAlignment) ? 'left' : 'center'),
  },
});

const fadeInContent = (val) => `
${
  val
    ? css`
        opacity: 0;
        transform: translateY(60px);
      `
    : css`
        opacity: 1;
        transform: translateY(0);
      `
};

  position: relative;
  transition: opacity 1s, transform 1s;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
`;

export const HeadingContainer = styled.div`
  ${({ delayStageContent }) => fadeInContent(delayStageContent === 'all')};
  z-index: 10;
  ${handleAlignment}
`;

export const ContentContainer = styled.div`
  ${({ delayStageContent }) =>
    fadeInContent(delayStageContent === 'all' || delayStageContent === 'excludeHeading')};
  z-index: 10;
  ${handleAlignment}
`;

export const CTAText = responsiveContent(
  styled.span`
    ${injectOSXFontSmoothing()}
  `
);

export const CTAsContainer = styled.div`
  margin-top: 32px;
  z-index: 10;
  ${createMediaQuery({
    'justify-content': (p) => p.ctaHorizontalLayout,
    xs: {
      display: 'flex',
    },
    m: {
      position: 'relative',
      bottom: 'auto',
      left: 'auto',
      right: 'auto',
    },
    s: (p) =>
      p.ctaVerticalAlignmentSmall === 'bottom'
        ? {
            position: 'absolute',
            display: 'flex',
            bottom: '30px',
            left: `${sideSpacing2Cols}%`,
            rgiht: `${sideSpacing2Cols}%`,
          }
        : {
            display: 'flex',
            position: 'relative',
          },
  })}
`;

const logoAlignmentMap = {
  left: {
    'margin-left': 0,
    'margin-right': 0,
  },
  center: {
    'margin-left': 'auto',
    'margin-right': 'auto',
  },
  right: {
    'margin-left': 0,
    'margin-right': 0,
  },
};

export const LogoH1Wrapper = styled.h1`
  margin: 0;

  ${createMediaQuery({
    s: (p) => logoAlignmentMap[p.horizontalAlignmentSmall],
    m: (p) => logoAlignmentMap[p.horizontalAlignmentSmall],
  })}

  ${({ horizontalAlignment }) => (horizontalAlignment === 'center' ? 'text-align: center' : '')}
`;

export const LogoPWrapper = styled.p`
  margin: 0;

  ${createMediaQuery({
    s: (p) => logoAlignmentMap[p.horizontalAlignmentSmall],
    m: (p) => logoAlignmentMap[p.horizontalAlignmentSmall],
  })}

  ${({ horizontalAlignment }) => (horizontalAlignment === 'center' ? 'text-align: center' : '')}
`;

const layoutMap = {
  horizontal: 'row',
  vertical: 'column',
};

export const StyledButtonGroup = styled(ButtonGroup)`
  /* for cta text */
  span {
    ${injectTypography('copy1')}
  }

  /* for alignment of buttons */
  > div {
    ${createMediaQuery({
      'flex-direction': {
        xs: (p) => layoutMap[p.layout.xs],
        s: (p) => layoutMap[p.layout.s],
        m: (p) => layoutMap[p.layout.m],
        l: (p) => layoutMap[p.layout.l],
        xl: (p) => layoutMap[p.layout.xl],
        xxl: (p) => layoutMap[p.layout.xxl],
      },
    })}
  }
`;
